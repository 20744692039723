import * as bodyScrollLock from 'body-scroll-lock';

const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const bodyScrollLockOptions = { reserveScrollBarGap: true };

function getScrollbarWidth() {
  var outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  document.body.appendChild(outer);
  var widthNoScroll = outer.offsetWidth;
  outer.style.overflow = "scroll";
  var inner = document.createElement("div");
  inner.style.width = "100%";
  inner.style.opacity = 0;
  outer.appendChild(inner);
  var widthWithScroll = inner.offsetWidth;
  outer.parentNode.removeChild(outer);
  return widthNoScroll - widthWithScroll;
}

function haveScroll() {
  var body = document.body, html = document.documentElement;

  var height = Math.max( body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight );

  return height > window.innerHeight
}

const scrollLock = function(el, callback) {
  window.lockedEl = el
  disableBodyScroll(el, bodyScrollLockOptions)

  if (callback) {
    setTimeout(() => {
      callback(haveScroll() ? getScrollbarWidth() : 0)
    }, 0)
  }
}

const scrollUnLock = function(callback) {
  if (window.lockedEl) {
    enableBodyScroll(window.lockedEl, bodyScrollLockOptions)

    if (callback) {
      setTimeout(callback, 0)
    }
  }
}

export { scrollLock, scrollUnLock }
